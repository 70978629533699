/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { DateTime } from 'luxon';

import styled from 'styled-components';

import { RichText } from 'prismic-reactjs';
import Link from '../Link';
import Image from '../Image';

import { breakpoint } from '../../theme';

const Thumbnail = styled.div`
  display: block;
  width: 100%;
  background-color: ${(props) => props.theme.palette.colors.black};
  overflow: hidden;
`;

const Inner = styled(Link)`
  display: block;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.global.border.color};
  text-align: left;
`;

const ThumbnailPlaceholder = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: ${(props) => props.theme.palette.colors.grey};
`;

const Content = styled.div`
  display: block;
  padding: ${(props) => props.theme.spacing.gutter} 0;
  width: 100%;
  color: ${(props) => props.theme.palette.colors.white};
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin: 0;
  font-size: ${rem(22)};

  ${breakpoint('large')} {
    font-size: ${rem(26)};
  }
`;

const Tags = styled.h4`
  display: block;
  width: 100%;
  margin: 0;
  font-size: ${rem(16)};

  ${breakpoint('large')} {
    font-size: ${rem(17)};
  }
`;

const PostDate = styled.time`
  display: block;
  width: 100%;
  margin: 0 0 ${rem(14)};
  font-size: ${rem(14)};
  color: ${(props) => props.theme.palette.colors.greyLight};

  ${breakpoint('large')} {
    font-size: ${rem(15)};
  }
`;

const ReadMore = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${(props) => props.theme.palette.colors.primary};
  padding: 0.7em 0;
  font-size: ${(props) => props.theme.typography.button.buttonSizes.default};
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.weights.button.normal};
  text-transform: ${(props) => props.theme.typography.button.textTransform};
  letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
  transition: ${(props) =>
    `all ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
`;

const Root = styled.div`
  width: 100%;

  & :hover {
    ${Thumbnail} {
      opacity: 0.8;
    }
  }
`;

function ArticleItem({ data }) {
  return (
    <Root>
      <Inner to={data}>
        <Thumbnail>
          {data.featured_image ? (
            <Image
              fluid={data.featured_imageSharp?.childImageSharp?.fluid}
              fallback={data.featured_image}
              alt={RichText.asText(data.title)}
            />
          ) : (
            <ThumbnailPlaceholder />
          )}
        </Thumbnail>
        <Content>
          <PostDate>
            {DateTime.fromISO(data._meta.firstPublicationDate).toFormat(
              'dd LLL, yyyy',
            )}
          </PostDate>
          <Title>{RichText.asText(data.title)}</Title>
          <Tags>{data.category}</Tags>

          <ReadMore>Läs mer</ReadMore>
        </Content>
      </Inner>
    </Root>
  );
}

ArticleItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ArticleItem;
